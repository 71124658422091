import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { Order } from '../company/modules/commercial/models/order.model';

/**
 * Sets various properties on an order object based on its details.
 * The properties include auction dates, business details, arbitration chamber, payment conditions, and more.
 * 
 * @param {Order} order - The order object to set properties on.
 * @param {TranslateService} translateService - The translation service used for localizing property names.
 * @param {string} [key="WORKING_ORDERS"] - The translation key prefix, default is "WORKING_ORDERS".
 */
export function setProp(order: Order, translateService: TranslateService, key: string = 'WORKING_ORDERS') {
  if (order.type.id === 4 && order.auction && order.auction.date_from && order.auction.date_to) {
    const datePipe = new DatePipe('es-AR');
    const date_from = new Date(order.auction.date_from);
    const date_to = new Date(order.auction.date_to);
    const duration = date_to.getTime() - date_from.getTime();

    order.props.push({
      name: translateService.instant(key + '.AUCTION_DATE'),
      value: datePipe.transform(date_from, translateService.instant(key + '.AUCTION_DATE_FORMAT')),
      class: 'highlighted'
    });

    order.props.push({
      name: translateService.instant(key + '.AUCTION_DURATION'),
      value: (duration / 1000 / 60) + ' ' + translateService.instant(key + '.MINUTES'),
      class: 'highlighted'
    });
  }

  if (order.business_detail.price.type === 'to_be_fixed') {
    // Price width conditions
    order.props.push({
      name: translateService.instant('GLOBAL.FIXING_CONDITIONS'),
      value: order.business_detail.price.value.toString()
    });
  }

  if (order.business_detail.price.type === 'consignment') {
    // Price width conditions
    order.props.push({
      name: translateService.instant(key + '.CONSIGNMENT_CONDITIONS'),
      value: order.business_detail.price.value.toString()
    });
  }

  if (order.business_detail.harvest) {
    order.props.push({
      name: translateService.instant('GLOBAL.CROP_YEAR'),
      value: order.business_detail.harvest
    });
  }

  if (order.arbitration_chamber && order.arbitration_chamber.name) {
    order.props.push({
      name: translateService.instant(key + '.ARBITRATION_CHAMBER'),
      value: order.arbitration_chamber.name
    });
  }

  if (order.payment_detail.payment_condition && order.payment_detail.payment_condition.name) {
    let value = '';
    if (order.payment_detail.payment_condition.slug === 'other') {
      value = order.payment_detail.other;
    } else {
      value = order.payment_detail.payment_condition.name;
    }
    order.props.push({
      name: translateService.instant('GLOBAL.PAYMENT_CONDITION'),
      value: value
    });
  }

  if (order.commission) {
    order.props.push({
      name: translateService.instant('GLOBAL.COMMISSION'),
      value: order.commission + '%'
    });
  }

  if (order.general_observations) {
    order.props.push({
      name: translateService.instant('GLOBAL.OBSERVATIONS'),
      value: order.general_observations
    });
  }

  if (order.business_detail.origin) {
    order.props.push({
      name: translateService.instant('GLOBAL.ORIGIN'),
      value: order.business_detail.origin.name
    });
  }

  if (order.has_contract_change) {
    order.props.push({
      name: translateService.instant('GLOBAL.CONTRACT'),
      value: translateService.instant(key + '.CONTRACT_DEVIATIONS')
    });
  }
}

/**
 * Generates a list of abbreviated crop years based on the current year.
 * 
 * @param {number} yearsBack - The number of years back from the current year to include in the list. Default is 3.
 * @param {number} yearsForward - The number of years forward from the current year to include in the list. Default is 1.
 * @returns {string[]} An array of abbreviated crop years in the format "YY/YY".
 * 
 * @example
 * // Assuming the current year is 2023:
 * genCropList(3, 1);
 * // Returns ["20/21", "21/22", "22/23", "23/24", "24/25"]
 */
export function genCropList(yearsBack: number = 3, yearsForward: number = 1): string[] {
  const currentYear: number = new Date().getFullYear();
  const cropList: string[] = [];

  for (let i = -yearsBack; i <= yearsForward; i++) {
    const cropYear = `${(currentYear + i) % 100}/${((currentYear + i + 1) % 100).toString().padStart(2, '0')}`;
    cropList.push(cropYear);
  }

  return cropList;
}