import { Pipe, PipeTransform } from '@angular/core';

import { Product } from '../../models/product.model';

function parseAttributes(product: Product) {
  const attributes = [];

  for (let key in product.attributes.quality) {
    if (product.attributes.quality[key]) {
      attributes.push({
        key: key,
        data: product.attributes.quality[key]
      });
    }
  }

  attributes.sort((a, b) => {
    return parseInt(a.data.order) - parseInt(b.data.order);
  });

  return attributes;
}

export function qualityToString(value: any = {}, product?: Product): string {
  let quality = [];
  let separator = ',';

  if (product && product.attributes && product.attributes.quality) {
    const attributes = parseAttributes(product);

    quality = attributes.filter(attr => {
      return value && value[attr.key] &&
        (!value || !attr.data.empty || attr.data.empty.slug !== value[attr.key].slug);
    }).map(attr => {
      if (attr.key === 'quality_specs' || attr.key === 'packaging') {
        separator = ';';
        let str = attr.data.abbrev ? (attr.data.abbrev + ' ') : '';
        str += value[attr.key].replace(/(\r\n|\n|\r)/gm, " ");
        return str;
      }

      // HACK for older orders (abbrev || name)
      return attr.data.abbrev + ' ' + (value[attr.key].abbrev || value[attr.key].name);
    });
  }

  return quality.join(separator + ' ');
}

export function qualityInfo(value: any = {}, product?: Product): string {
  let quality = [];

  if (product && product.attributes && product.attributes.quality) {
    const attributes = parseAttributes(product);

    quality = attributes.filter(attr => {
      return value && value[attr.key] &&
        (!value || !attr.data.empty || attr.data.empty.slug !== value[attr.key].slug);
    }).map(attr => {
      if (attr.key === 'quality_specs' || attr.key === 'packaging') {
        return (attr.data.name ? attr.data.name : '') + ': ' + value[attr.key];
      } else {
        return attr.data.name + ': ' + value[attr.key].name;
      }
    });
  }

  return quality.join('<br>');
}

@Pipe({
  name: 'quality'
})
export class QualityPipe implements PipeTransform {

  transform(value: any = {}, product?: Product): any {
    return qualityToString(value, product);
  }
}
